import {
  BookmarkOutlineIcon,
  BookmarkFillIcon,
  variants,
  IconButton,
} from '@resident-advisor/design-system'
import LoginModal from 'components/auth/modals/login/LoginModal'
import { useUserContext } from 'context/UserContext'
import { useState } from 'react'
import { TRACKING_EVENT, TRACKING_PROPERTY } from 'scripts/tracking'
import useSavedEventsToggleInterest from 'hooks/useSavedEventsToggleInterest/useSavedEventsToggleInterest'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import SlashAnimation from 'components/shared/animation/SlashAnimation'
import styled from 'styled-components'
import Tracking from 'scripts/tracking/Tracking'
import testIds from 'enums/testIds'
import useSavedEventMixpanelProperties from '../useEventSavedMixpanelProperties'

function SavedEventsButton({
  type,
  isSaved = false,
  isInterested = false,
  eventId = null,
  interestedCount = null,
  source = null,
}: {
  type: SavedEventsButtonType
  isSaved?: boolean
  isInterested?: boolean
  eventId?: string
  interestedCount?: number
  source?: EventSavedSourceType
}) {
  let cssStyles

  const { user } = useUserContext()
  const [showLogin, setShowLogin] = useState(false)
  const { toggle } = useSavedEventsToggleInterest(
    eventId,
    isInterested,
    interestedCount
  )

  const mixpanelProperties = useSavedEventMixpanelProperties(eventId, source)

  const onShowInterest = () => {
    if (!isSaved) {
      Tracking.trackMixpanel(TRACKING_EVENT.eventSaved, mixpanelProperties.data)
    }
    toggle()
  }

  switch (type) {
    case SavedEventsButtonType.EventCardIcon:
      cssStyles = {
        iconSize: 12,
        buttonSize: 26,
        variant: variants.iconButton.quaternary,
      }
      break
    default:
      cssStyles = {
        iconSize: 24,
        buttonSize: 40,
        variant: variants.iconButton.tertiary,
      }
  }

  return (
    <>
      <StyledIconButton
        size={cssStyles.buttonSize}
        iconSize={cssStyles.iconSize}
        isActive={isSaved}
        data-testid={testIds.savedEventsButton}
        onClick={() => {
          if (user) return onShowInterest()
          return setShowLogin(true)
        }}
        Icon={isSaved ? BookmarkFillIcon : BookmarkOutlineIcon}
        variant={cssStyles.variant}
        data-button-tracking-id={`${buttonTrackingIds.saveEventButton}-${
          isSaved ? 'unsave-event' : 'save-event'
        }`}
      >
        <SlashAnimation isActive={isSaved} size={cssStyles.buttonSize} />
      </StyledIconButton>
      {showLogin && (
        <LoginModal
          toggleShow={() => setShowLogin(!showLogin)}
          onSuccess={onShowInterest}
          source={TRACKING_PROPERTY.loginSource.savedEvents}
        />
      )}
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: relative;
  overflow: hidden;
`

enum SavedEventsButtonType {
  EventCardIcon,
  EventDetailHeaderIcon,
}

enum EventSavedSourceType {
  EventListingsCard = 'Event Listings Card',
  EventDetailHeader = 'Event Detail Header',
  PopularSection = 'Popular Section',
  PicksSection = 'Picks Section',
}
export default SavedEventsButton
export { SavedEventsButtonType, EventSavedSourceType }
