import { gql } from '@apollo/client'

const GET_SAVED_EVENT_FOR_MIXPANEL_PROPERTIES = gql`
  query GET_SAVED_EVENT_FOR_MIXPANEL_PROPERTIES($id: ID!) {
    event(id: $id) {
      isTicketed
      interestedCount
      venue {
        area {
          name
          country {
            name
          }
        }
      }
      promoters {
        id
      }
      artists {
        id
        name
      }
      tickets(queryType: AVAILABLE) {
        validType
        onSaleFrom
        onSaleUntil
      }
    }
  }
`

export default GET_SAVED_EVENT_FOR_MIXPANEL_PROPERTIES
