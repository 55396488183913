import arrayHasData from 'lib/arrayHasData'
import TICKET_VALID_TYPE from 'enums/ticket-valid-type'
import { EventSavedSourceType } from 'components/user/pages/saved-events/saved-events-button/SavedEventsButton'
import { useQuery } from '@apollo/client'
import GET_SAVED_EVENT_FOR_MIXPANEL_PROPERTIES from 'components/events/pages/events-home/event-listings/queries/GetEventSavedMixpanelProperties'
import TicketDto from 'interfaces/gql/TicketDto'

const useSavedEventMixpanelProperties = (
  eventId: string,
  source: EventSavedSourceType
) => {
  const queryResult = useQuery(GET_SAVED_EVENT_FOR_MIXPANEL_PROPERTIES, {
    variables: {
      id: eventId,
    },
  })

  const event = queryResult?.data?.event

  return {
    data: {
      'Event ID': eventId.toString(),
      'RA Country': event?.venue?.area?.country?.name,
      'RA Region': event?.venue?.area?.name,
      'Lineup Artist Names': arrayHasData(event?.artists)
        ? event?.artists?.reduce((acc, artist) => [...acc, artist.name], [])
        : [],
      'Lineup Artist IDs': arrayHasData(event?.artists)
        ? event?.artists?.reduce((acc, artist) => [...acc, artist.id], [])
        : [],
      Promoters: arrayHasData(event?.promoters)
        ? event?.promoters?.reduce((acc, promoter) => [...acc, promoter.id], [])
        : [],
      'Tickets on sale': eventHasTicketsOnSale(event?.tickets),
      'Ticketed Event': event?.isTicketed,
      'Interested Count': event?.interestedCount,
      'Sold Out': eventIsSoldOut(event?.tickets),
      Source: `Saved from ${source}`,
    },
    loading: false,
  }
}

const eventIsSoldOut = (tickets: TicketDto[]) => {
  return !tickets?.some(
    (ticket) => ticket.validType !== TICKET_VALID_TYPE.soldOut
  )
}

const eventHasTicketsOnSale = (tickets: TicketDto[]) => {
  return tickets?.some(
    (ticket) =>
      ticket.onSaleFrom <= new Date() &&
      ticket.onSaleUntil >= new Date() &&
      ticket.validType === TICKET_VALID_TYPE.valid
  )
}

export default useSavedEventMixpanelProperties
export { eventIsSoldOut, eventHasTicketsOnSale }
